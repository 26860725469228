import React, { useContext } from "react";
import { OktaAuth } from "@okta/okta-auth-js";
import saveAs from "file-saver";
import {
  cjAcceptableRiskTimeline,
  cjCoveredOverTime,
  allCoverageOverTime,
  cjOverTime,
  cjPatchedMultiBar,
  cjRiskOverTime,
  criticalityFunnel,
  hopsPie,
  riskAssetsMultiBar,
  riskConnectionsMultiBar,
  vulnMgmtIntegrations,
  alertMgmtIntegrations,
  riskyAssetsTable,
  avgRiskByApp,
  avgRiskByBizFunction,
  timeToPatch,
} from "./reporting/data";

function serializeQueryParams(obj) {
  const str = [];
  for (const [key, value] of Object.entries(obj)) {
    if (value !== "" && value !== undefined && value !== null) {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return str.join("&");
}

class API {
  constructor(accessToken) {
    this.accessToken = accessToken;
  }

  getConnectorOptions() {
    return this.perform("get", "/settings/options");
  }

  pipelineStatus(connectorId) {
    console.log(connectorId)
    return this.perform("get", `/settings/upload/status/${connectorId}`, null, 'text');
  }

  runPipeline(connectorId) {
    return this.perform("post", `/settings/upload/trigger/${connectorId}`, {});
  }

  resetPassword(currentPassword, newPassword) {
    return this.perform("post", "/user/reset", {
      current_password: currentPassword,
      new_password: newPassword,
    });
  }

  requestPasswordReset(email) {
    return this.perform("post", "/user/forgot-password", { email });
  }

  resetPasswordWithCode({ token, newPassword }) {
    return this.perform("post", "/user/set-password", {
      token,
      new_password: newPassword,
    });
  }

  getUsers() {
    return this.perform("get", "/users");
  }

  deleteUser(userId) {
    return this.perform("delete", "/users", { user_id: userId });
  }

  inviteUser(email) {
    return this.perform("post", "/users", { email });
  }

  createAsset(assetData) {
    return this.perform("post", "/asset/new", {
      ...assetData,
    });
  }

  deleteAsset(assetId) {
    return this.perform("delete", `/assets/${assetId}`);
  }

  updateAsset(assetId, overrideData, assetUpdatedData) {
    return this.perform("post", "/asset", {
      asset_id: assetId,
      ...overrideData,
      ...assetUpdatedData,
    });
  }

  alertsStats() {
    return this.perform("get", "/alerts/stats");
  }

  securityPostureStats() {
    return this.perform("get", "/crownjewels/securityposturestats");
  }

  otarSecurityGaps() {
    return this.perform("get", "/crownjewels/otarsecuritygaps");
  }

  topHighAlerts() {
    return this.perform("get", "/alerts/top");
  }

  attributeToAssetId(type, value) {
    return this.perform("get", `/assets/lookup/${type}/${value}`);
  }

  logout() {
    return this.perform("post", "/logout");
  }

  getSettings() {
    return this.perform("get", "/settings");
  }

  updateSettings(settings) {
    return this.perform("post", "/settings", settings);
  }

  updateUser(user) {
    return this.perform("post", "/settings/user", user);
  }

  updateCompanyProfile(company) {
    return this.perform("post", "/settings/company_profile", company);
  }

  settingsNavbar() {
    return this.perform("get", "/settings/navbar");
  }

  companyUsers() {
    return this.perform("get", "/settings/users");
  }

  getCompany() {
    // Refactored endpoint for getting company info
    return this.perform("get", "/settings/company");
  }

  companyProfile() {
    // Legacy endpoint for getting company info
    return this.perform("get", "/settings/company_profile");
  }

  dataUploads() {
    return this.perform("get", "/settings/uploads");
  }

  getConnectors() {
    return this.perform("get", "/settings/connectors");
  }

  getConnectorsByType(type) {
    return this.perform("get", `/settings/connectors_by_type/${type}`);
  }

  getConnector(id) {
    return this.perform("get", `/settings/connectors/${id}`);
  }

  upsertConnector(id, data) {
    return this.perform("post", `/settings/connectors/${id}`, data);
  }

  getConnectorFields() {
    return this.perform("get", "/settings/connector_fields");
  }

  async upload(file, solutionName, connectorId) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("connector_id", connectorId);
    formData.append("solution_name", solutionName);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        // "Content-Type": "application/octet-stream"
        // 'Content-Type': 'multipart/form-data'
      },
    };

    requestOptions.body = formData;

    const res = await fetch(
      `/api/settings/upload/${solutionName}`,
      requestOptions
    );
    return res.json();
  }

  factors() {
    return this.perform("get", "/settings/factors");
  }

  factorPost(name, newValue) {
    return this.perform("post", `/settings/factor/${name}`, {
      value: newValue,
    });
  }

  deleteConnector(id) {
    return this.perform("delete", `/settings/connectors/${id}`);
  }

  getSolutions() {
    return this.perform("get", "/settings/solutions");
  }

  getSolution(id) {
    return this.perform("get", `/settings/solutions/${id}`);
  }

  deleteSolution(id) {
    return this.perform("delete", `/settings/solutions/${id}`);
  }

  upsertSolution(id, data) {
    return this.perform("post", `/settings/solutions/${id}`, data);
  }

  getDatasources() {
    return this.perform("get", "/settings/datasources");
  }

  getDatasource(id) {
    return this.perform("get", `/settings/datasources/${id}`);
  }

  deleteDatasource(id) {
    return this.perform("delete", `/settings/datasources/${id}`);
  }

  upsertDatasource(id, data) {
    return this.perform("post", `/settings/datasources/${id}`, data);
  }

  connectorStats(weeks) {
    let url = "/settings/connectors/stats";
    if (weeks) {
      url += `?weeks=${weeks}`;
    }

    return this.perform("get", url);
  }

  async putDevoSslCredentials(keyFile, certFile, chainFile) {
    const formData = new FormData();
    formData.append("key", keyFile);
    formData.append("certificate", certFile);
    formData.append("chain", chainFile);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };

    requestOptions.body = formData;

    const res = await fetch(`/api/settings/export/devo/config`, requestOptions);

    if (res.status === 401) {
      if (window._env_.REACT_APP_AUTH_TYPE === "okta") {
        const oktaAuth = new OktaAuth({
          issuer: `https://${window._env_.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
          clientId: window._env_.REACT_APP_OKTA_ID,
        });

        oktaAuth.signOut();
      }

      window.location.href = "/login";
    }
    return res.json();
  }

  async putRestExportCredentials(url, username, password, batchingSize, batchLimit) {
    const formData = new FormData();
    formData.append("url", url);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("batchingSize", batchingSize);
    formData.append("batchLimit", batchLimit);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };

    requestOptions.body = formData;

    const res = await fetch(`/api/settings/export/rest/config`, requestOptions);

    if (res.status === 401) {
      if (window._env_.REACT_APP_AUTH_TYPE === "okta") {
        const oktaAuth = new OktaAuth({
          issuer: `https://${window._env_.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
          clientId: window._env_.REACT_APP_OKTA_ID,
        });

        oktaAuth.signOut();
      }

      window.location.href = "/login";
    }
    return res.json();
  }

  getDevoSslCredentials() {
    return this.perform("get", "/settings/export/devo/config");
  }

  getRestCredentials() {
    return this.perform("get", "/settings/export/rest/config");
  }

  applicationSummary() {
    return this.perform("get", "/reporting/applications/summary");
  }

  appsVuln() {
    return this.perform("get", "/reporting/application_vuln");
  }

  getApplications() {
    return this.perform("get", "/applications");
  }

  getBusinessProcesses() {
    return this.perform("get", "/business-processes");
  }

  getApplicationsDetails() {
    return this.perform("get", "/applications/details");
  }

  getApplication(id) {
    return this.perform("get", `/applications/${id}`);
  }

  getApplicationAssets(id) {
    return this.perform("get", `/applications/${id}/assets`);
  }

  addApplicationAssets(id, assetIds) {
    return this.perform("post", `/applications/${id}/assets`, {
      asset_ids: assetIds,
    });
  }

  removeApplicationAssets(assetIds) {
    return this.perform("delete", `/applications/assets`, {
      asset_ids: assetIds,
    });
  }

  bulkUpdateAssets(assetIds, mappings) {
    return this.perform("post", `/manage/assets`, {
      asset_ids: assetIds,
      mappings,
    });
  }

  getApplicationDetails(id) {
    return this.perform("get", `/applications/${id}/details`);
  }

  updateApplication(id, application) {
    return this.perform("post", `/applications/${id}`, application);
  }

  deleteApplication(id) {
    return this.perform("delete", `/applications/${id}`);
  }

  createApplication(application) {
    return this.perform("post", `/applications`, application);
  }

  // allApplications() {
  //   return this.perform("get", "/applications");
  // }

  // getApplication(applicationId) {
  //   return this.perform("get", `/application/${applicationId}`);
  // }

  // upsertApplication(applicationId, data) {
  //   if (applicationId) {
  //     return this.perform("post", `/application/${applicationId}`, data);
  //   }
  //   return this.perform("post", `/application/new`, data);
  // }

  resilienceApplicationConnections(appId, filters) {
    let url = `/resilience/application/${appId}`;

    if (filters) {
      const serializedParams = serializeQueryParams(filters);
      if (serializedParams && serializedParams !== "none") {
        url += `?${serializedParams}`;
      }
    }

    return this.perform("get", url);
  }

  resilienceInsights() {
    return this.perform("get", "/resilience/insights");
  }

  resilienceBaseUrl(baseUrl, filters) {
    let url = `/resilience/${baseUrl}`;

    if (filters) {
      const serializedParams = serializeQueryParams(filters);
      if (serializedParams && serializedParams !== "none") {
        url += `?${serializedParams}`;
      }
    }

    return this.perform("get", url, undefined);
  }

  resilienceCsvBaseUrl(baseUrl, assetId, filters) {
    let url = `/resilience/csv/${baseUrl}`;

    if (assetId) {
      url += `/${assetId}`;
    }

    if (filters) {
      const serializedParams = serializeQueryParams(filters);
      if (serializedParams && serializedParams !== "none") {
        url += `?${serializedParams}`;
      }
    }

    return this.perform("get", url, undefined, "csv");
  }

  resilience(filters) {
    let filterUrl = "/resilience/graph/";
    if (filters[0]) {
      filterUrl += filters[0];
    }

    if (filters[1]) {
      filterUrl += `/${filters[1]}`;
    }

    return this.perform("get", filterUrl);
  }

  // these can be removed once Network.js is deprecated
  resilienceOneFilter(filter) {
    return this.perform("get", `/resilience/graph/${filter}/null`);
  }

  resilienceTwoFilters(filter1, filter2) {
    return this.perform("get", `/resilience/graph/${filter1}/${filter2}`);
  }

  criticalAssets() {
    return this.perform("get", "/assets/critical");
  }

  crownJewelsDetails(asset) {
    return this.perform("get", `/assets/details/${asset}`);
  }

  allAssetsSearch(query) {
    return this.perform("get", `/assets/search/${query}`);
  }

  assetsFacetedSearch(query) {
    return this.perform("get", `/search/assets?${query}`);
  }

  assetsCsvFacetedSearch(query) {
    return this.perform("get", `/search/csv/assets?${query}`);
  }

  assetsFacets() {
    return this.perform("get", "/search/assets/filters");
  }

  lowestCritical() {
    return this.perform("get", "/assets/least_is_critical");
  }

  crownJewelsBubble(groupby) {
    return this.perform(
      "get",
      `/crownjewels/bubble?groupby=${groupby}&filters=external=false`
    );
  }

  crownJewelsBubbleSubnet(subnet, bits) {
    return this.perform("get", `/crownjewels/bubble/subnet/${subnet}/${bits}`);
  }

  subnetCount() {
    return this.perform("get", `/crownjewels/subnetCount`);
  }

  crownJewelsBubbleCacheRegenerate() {
    return this.perform("get", "/crownjewels/bubble/regenerate");
  }

  crownJewelsSubnet(subnet) {
    return this.perform("get", `/crownjewels/subnet/${subnet}`);
  }

  latestKeyAssets() {
    return this.perform("get", "/keyassets/latest");
  }

  crownJewels(csv = false) {
    return this.perform("get", "/crownjewels", undefined, csv ? "csv" : "json");
  }

  crownJewelsToDevo() {
    return this.perform("post", "/crownjewels/export/devo");
  }

  criticalityByAssetType() {
    return this.perform("get", "/assets/asset_type");
  }

  allAssetTypeCount() {
    return this.perform("get", "/assets/assetTypeCount");
  }

  criticalityBySubnet() {
    return this.perform("get", "/assets/criticality_by_subnet");
  }

  connectionsPerCritical() {
    return this.perform("get", "/assets/dashboard/connections_per_critical");
  }

  assetsByLocation() {
    return this.perform("get", "/assets/dashboard/location");
  }

  assetsByCVEs() {
    return this.perform("get", "/reporting/assets_by_cves");
  }

  assetsByLogons() {
    return this.perform("get", "/reporting/logons");
  }

  assetsByAlerts() {
    return this.perform("get", "/reporting/alerts-breakdown");
  }

  assetsCVEsBreakdown() {
    return this.perform("get", "/reporting/cves-breakdown");
  }

  newAssetsChart() {
    return this.perform("get", "/reporting/new_assets");
  }

  lastSeenChart() {
    return this.perform("get", "/reporting/last_seen");
  }

  assetsMostVulns() {
    return this.perform("get", "/reporting/high_vuln_critical");
  }

  riskV2Asset(assetId) {
    return this.perform("get", `/risk/assets/${assetId}`);
  }

  riskV2Industry() {
    return this.perform("get", `/risk/params/industry`);
  }

  riskV2UpdateCompany(data) {
    return this.perform("post", `/risk/params/company`, data);
  }

  riskV2AssetParams() {
    return this.perform("get", `/risk/params/asset`);
  }

  riskV2UpdateAssetParams(data) {
    return this.perform("post", `/risk/params/asset`, data);
  }

  riskV2Company() {
    return this.perform("get", `/risk/params/company`);
  }

  riskV2HighImpactAssets() {
    return this.perform("get", "/risk/assets?is_critical=true");
  }

  riskV2Assets(limit) {
    let url = "/risk/assets";
    if (limit) {
      url += `?limit=${limit}`;
    }
    return this.perform("get", url);
  }

  impactAssetParams() {
    return this.perform("get", `/impact/params/asset`);
  }

  impactUpdateAssetParams(data) {
    return this.perform("post", `/impact/params/asset`, data);
  }

  impactAsset(assetId) {
    return this.perform("get", `/impact/assets/${assetId}`);
  }

  risk() {
    return this.perform("get", "/risk/assets");
  }

  riskAggregation(groupBy, orderBy = "risk_score", totalRiskScore = "false") {
    return this.perform(
      "get",
      `/risk/agg?group_by=${groupBy}&order_by=${orderBy}&total_risk_score=${totalRiskScore}`
    );
  }

  highRiskAggregation() {
    return this.perform("get", `/risk/highriskagg`);
  }

  healthcheck() {
    return this.perform("get", "/healthcheck");
  }

  // Reporting
  cjAcceptableRisk() {
    return this.localPerform(cjAcceptableRiskTimeline);
  }

  cjCovered() {
    return this.localPerform(cjCoveredOverTime);
  }

  allCoverage() {
    return this.localPerform(allCoverageOverTime);
  }

  cjTime() {
    return this.localPerform(cjOverTime);
  }

  cjPatchedMulti() {
    return this.localPerform(cjPatchedMultiBar);
  }

  cjRiskTime() {
    return this.localPerform(cjRiskOverTime);
  }

  criticalityFunnel() {
    return this.localPerform(criticalityFunnel);
  }

  cjDistanceFromInternet() {
    return this.localPerform(hopsPie);
  }

  avgRiskByApp() {
    return this.localPerform(avgRiskByApp);
  }

  avgRiskByBizFunction() {
    return this.localPerform(avgRiskByBizFunction);
  }

  timeToPatch() {
    return this.localPerform(timeToPatch);
  }

  riskAssetsMulti() {
    return this.localPerform(riskAssetsMultiBar);
  }

  riskConnectionsMulti() {
    return this.localPerform(riskConnectionsMultiBar);
  }

  vulnMgmtIntegrations() {
    return this.localPerform(vulnMgmtIntegrations);
  }

  riskByType() {
    return this.perform("get", "/reporting/risk_by_type");
  }

  alertMgmtIntegrations() {
    return this.localPerform(alertMgmtIntegrations);
  }

  assetCVEs(assetId) {
    return this.perform("get", `/assets/vulns/${assetId}`);
  }

  assetDataDiscovered(assetId) {
    return this.perform("get", `/assets/data/${assetId}`);
  }

  assetAlerts(assetId) {
    return this.perform("get", `/assets/alerts/${assetId}`);
  }

  topAlerts(limit) {
    return this.perform("get", `/reporting/alerts?limit=${limit}`);
  }

  topCVEs(limit) {
    return this.perform("get", `/reporting/cves?limit=${limit}`);
  }

  kevs(internetFacing, criticalOnly) {
    const url = `/risk/kevs?internet_facing=${internetFacing ? "true" : "false"
      }&critical_only=${criticalOnly ? "true" : "false"}`;
    return this.perform("get", url);
  }

  allMetrics({ metrics, pattern, period }) {
    return this.perform("post", `/metrics/outcome-metrics?period=${period}`, {
      metrics,
      pattern,
    });
  }

  shadowITMetricsOverTime(criticalOnly) {
    return this.perform("post", `/assets/shadow-it-over-time?critical=${criticalOnly}`
    );
  }
  assetsOverTime(criticalOnly) {
    return this.perform("post", `/assets/assets-over-time?critical=${criticalOnly}`
    );
  }

  riskyAssetsTable() {
    return this.localPerform(riskyAssetsTable);
  }

  // For the CIO report for a customer use case
  assetOverview(groupBy, groupByFilter, activeOnly) {
    if (groupByFilter) {
      groupByFilter = encodeURIComponent(
        groupByFilter?.toString().replaceAll("%20", " ")
      );
    }

    let url = `/overview?group_by=${groupBy}&active=${activeOnly}`;
    if (groupByFilter) {
      url += `&${groupBy}=${groupByFilter}`;
    }

    return this.perform("get", url);
  }

  // For the CIO report for a customer use case
  cioOverview(solutionName, groupBy, activeOnly) {
    return this.perform(
      "get",
      `/overview/${solutionName}?group_by=${groupBy}&active=${activeOnly}`
    );
  }

  // For the CIO report for a customer use case
  vulnerabilitiesOverview(solutionName, groupBy, activeOnly) {
    return this.perform(
      "get",
      `/vuln-overview/${solutionName}?group_by=${groupBy}&active=${activeOnly}`
    );
  }

  getActiveSolutions() {
    return this.perform("get", "/controls/solutions");
  }

  riskyBreakdown(criticalOnly, activeOnly) {
    let url = "/risky-asset-breakdown?";
    if (criticalOnly) {
      url += "critical=true&";
    }
    if (activeOnly) {
      url += "active=true";
    }
    return this.perform("get", url);
  }

  kevsCount(criticalOnly) {
    let url = "/kevs-count?";
    if (criticalOnly) {
      url += "critical=true&";
    }
    return this.perform("get", url);
  }

  coverageSummary(activeOnly) {
    let url = "/controls/summary";

    if (activeOnly) {
      url += `?active=${activeOnly}`;
    }

    return this.perform("get", url);
  }

  coverageMatrix(filters) {
    let url = "/controls/matrix";
    if (filters) {
      const serializedParams = serializeQueryParams(filters);
      if (serializedParams && serializedParams !== "none") {
        url += `?${serializedParams}`;
      }
    }

    return this.perform("get", url);
  }

  assetTagCounts(filters) {
    let url = "/reporting/tags/asset";
    if (filters) {
      const serializedParams = serializeQueryParams(filters);
      if (serializedParams && serializedParams !== "none") {
        url += `?${serializedParams}`;
      }
    }

    return this.perform("get", url);
  }

  tagSummary() {
    return this.perform("get", "/reporting/tags/summary");
  }

  allTags() {
    return this.perform("get", "/tags");
  }

  tagValues(tagTypeId) {
    return this.perform("get", `/tags/${tagTypeId}`);
  }

  //*
  //* local perform method for local dummy data
  localPerform(data) {
    this.localPerform.bind(this);
    return Promise.resolve(data);
  }

  /**
   * Perform an HTTP request
   * @param {string} method HTTP method to use
   * @param {string} resource URL of the resource to request
   * @param {object} data JSON body for the request (if async)
   * @param {string} format Expected response format ('json' or 'csv')
   * @returns The parsed response, in the case of a JSON response
   */
  async perform(method, resource, data, format = "json") {
    const requestHeaders = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    if (data) {
      requestHeaders["Content-Type"] = "application/json";
    }

    // Tell the API what format we expect the response in
    switch (format) {
      case "json":
        requestHeaders.Accept = "application/json";
        break;
      case "csv":
        requestHeaders.Accept = "text/csv";
        break;
      default:
        requestHeaders.Accept = "*/*";
    }

    const requestOptions = {
      method: method.toUpperCase(),
      headers: requestHeaders,
    };

    if (data) {
      requestOptions.body = JSON.stringify(data);
    }

    const res = await fetch(`/api${resource}`, requestOptions);

    if (res.status === 401 || res.status === 422) {
      if (window._env_.REACT_APP_AUTH_TYPE === "okta") {
        const oktaAuth = new OktaAuth({
          issuer: `https://${window._env_.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
          clientId: window._env_.REACT_APP_OKTA_ID,
        });

        oktaAuth.signOut();
      }

      window.location.href = "/login";
    }

    // The response headers are an iteratable, we want to get an object
    const responseHeaders = {};
    for (const [key, value] of res.headers) {
      // Make headers all lower case, just for consistency in logic below
      responseHeaders[key.toLowerCase()] = value;
    }

    // `Content-Disposition: attachment; ...` indicates that we should download
    // the response instead of using it inline. This is useful for downloading
    // CSVs and the like
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
    const contentDisposition = responseHeaders["content-disposition"];
    if (/^attachment;/i.test(contentDisposition)) {
      // The Content-Disposition header should also tell us what to name the
      // file. If not, we'll fall back to a default. The filename may be wrapped
      // in quotes, and will end with either a semicolon or the end of the string.
      const matches = /filename=["']?([^'";]+)/i.exec(contentDisposition);
      const fileName = matches ? matches[1] : `keycaliber-data.txt`;
      saveAs(await res.blob(), fileName);

      return null;
    }

    // Treat everything else as JSON
    return res.json();
  }
}

export const APIContext = React.createContext({});

export function useApi() {
  return useContext(APIContext);
}

export default API;
