import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {
  Chip,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
  Tooltip,
  ButtonGroup,
  CircularProgress,
} from "@material-ui/core";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import debounce from "lodash.debounce";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LaunchIcon from "@material-ui/icons/Launch";
import TabPanel from "../components/TabPanel";
import { RoundedTab, RoundedTabs } from "../components/RoundedTab";
import PageNotFoundInner from "../404Inner";
import AssetsTable from "./AssetsTable";
import InventoryFilters from "./InventoryFilters";
import { HoverPopoverProvider } from "../components/HoverPopover";
import { useApi } from "../API";
import useQuery from "../hooks/useQuery";
import spinner from "../assets/res-spinner.svg";
import AssetIcon from "../components/AssetIcon";
import ConsolidatedAssetDetails from "./ConsolidatedAssetDetails";
import ApplicationsTable from "./ApplicationsTable";
import BusinessProcessesTable from "./BusinessProcessesTable";
import { numberWithCommas } from "../utils";
import downloadIcon from "../assets/downloadIcon.svg";

const DEFAULT_ROWS = 50;

const FLAG_LOGONS_COLUMN = window._env_.REACT_APP_FLAG_LOGONS_COLUMN === "true";

const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  bubbleMapWrapper: {
    justifyContent: "flex-start",
    minHeight: "calc(100vh - 440px)",
    padding: theme.spacing(2, 2),
  },
  buttonbar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    // this is a hack to get the vertical spacing right on the body components
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  secondaryNav: theme.layout.secondaryNav,
  tabPanel: {
    background: theme.palette.background,
  },
  secondaryNavToolbar: {
    "& > *": {
      margin: `0 ${theme.spacing(1)}px`,
    },
    marginTop: "-8px",
  },
  paper: {
    display: "flex",
  },
  modalPaper: {
    width: "calc(100% - 32px)",
    maxWidth: "1400px",
    margin: "100px auto",
    display: "block",
  },
}));

export const baseRoute = "/app/inventory";
const legacyDetailsRoute = `${baseRoute}/details/:assetId`;
const detailsRoute = `/app/asset/:assetId`;

const impactBaseHeaders = [
  {
    id: "criticality",
    label: "Impact Score",
    align: "center",
    format: (d, row) => {
      return (
        <div
          style={{
            paddingTop: "2px",
            paddingLeft: "20%",
            width: "75px",
          }}
        >
          <AssetIcon isCritical={row?.is_critical} criticalityPercentage={d} />
        </div>
      );
    },
  },
  {
    id: "risk_score",
    label: "Risk Score",
    align: "left",
    format: (d) => {
      return (
        <div
          style={{
            paddingTop: "2px",
            // paddingLeft: "10%",
            width: "75px",
            textAlign: "center",
          }}
        >
          <AssetIcon risk riskPercentage={d} />
        </div>
      );
    },
  },
  {
    id: "name",
    label: "Alias",
    // width: 115,
    align: "left",
  },
  {
    id: "ipv4",
    label: "IP",
    // width: 95,
    align: "left",
  },
  {
    id: "hostname",
    label: "Hostname",
    align: "left",
    width: "215px",
  },
  {
    id: "asset_uuid",
    label: "Asset UUID",
    align: "left",
    width: "215px",
  },
  ...(FLAG_LOGONS_COLUMN
    ? [
        {
          id: "last_logged_on_user",
          label: "Last Logon",
          align: "left",
        },
      ]
    : []),
  {
    id: "application_name",
    label: "Application",
    width: "175px",
    align: "left",
    format: (value) => value ?? "—",
  },
  {
    id: "business_process_name",
    label: "Business Process",
    width: "175px",
    align: "left",
    format: (value) => value ?? "—",
  },
  {
    id: "location",
    label: "Location",
    align: "left",
  },
  {
    id: "asset_type",
    label: "Asset Type",
    align: "left",
  },
  {
    id: "os",
    label: "OS",
    align: "left",
  },
  {
    id: "cve_count",
    label: "High/Crit Vulns",
    align: "center",
  },
  {
    id: "alerts_count",
    label: "Alerts",
    align: "center",
  },
  {
    id: "stale",
    label: "Stale/Current",
    align: "center",
    format: (d) => {
      return (
        <div style={{ textAlign: "center" }}>
          <Chip
            label={d ? "Stale" : "Current"}
            variant="outlined"
            style={
              d
                ? { color: "gray", borderColor: "gray" }
                : { color: "black", borderColor: "gray" }
            }
          />
        </div>
      );
    },
  },
  {
    id: "date_last_seen",
    label: "Last Seen",
    align: "left",
    format: (d) => (
      <Typography variant="body2" style={{ fontSize: "small" }}>
        {moment(d).fromNow()}
      </Typography>
    ),
  },
  {
    id: "business_unit",
    label: "Business Unit",
    align: "left",
  },
  ...(!FLAG_LOGONS_COLUMN
    ? [
        {
          id: "last_logged_on_user",
          label: "Last Logon",
          align: "left",
        },
      ]
    : []),
];

const addSolutionsToImpactHeaders = (solutions) => {
  const headers = [...impactBaseHeaders];

  for (const solution of solutions) {
    headers.push({
      id: solution.name,
      label: solution.display_name,
      icon_src: solution.logo_url,
      align: "center",
      width: "100px",
      maxWidth: "100px",
      type: "technology",
      solutionId: solution.id,
    });
  }
  return headers;
};

const AssetDrilldownModal = ({ assetId, handleBackButton }) => {
  const openTab = () => {
    const link = `${window.location.origin}${baseRoute}/details/${assetId}`;
    window.open(link, "_blank");
  };

  return (
    <Grid>
      <Grid
        container
        style={{ padding: ".5em 1em", marginBottom: ".5em" }}
        // justifyContent="space-between"
      >
        <ButtonGroup>
          <Button
            onClick={handleBackButton}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            color="secondary"
          >
            Return to Inventory
          </Button>
          <Tooltip title="View in New Tab">
            <Button
              endIcon={<LaunchIcon />}
              onClick={openTab}
              color="secondary"
              variant="outlined"
            />
          </Tooltip>
        </ButtonGroup>
      </Grid>
      <Divider />
      <ConsolidatedAssetDetails assetId={assetId} />
    </Grid>
  );
};

function CrownJewelsInner() {
  const defaultQuery = "";
  const classes = useStyles();
  const api = useApi();
  const searchQuery = useQuery();
  const history = useHistory();
  const location = useLocation();
  const parentRef = useRef(null);
  const [forceReload, setForceReload] = useState(false);
  const [docTitle, setDocTitle] = useState("KeyCaliber");
  const [data, setData] = useState();
  const [solutions, setSolutions] = useState([]);
  const [showDrilldownModal, setShowDrilldownModal] = useState(false);
  const [drilldownAssetId, setDrilldownAssetId] = useState(null);
  const [assetDataLoading, setAssetDataLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [enrichedAssetDataLoading, setEnrichedAssetDataLoading] =
    useState(true);
  const [applications, setApplications] = useState(null);
  const [businessProcesses, setBusinessProcesses] = useState(null);

  const updateUrlSearchParams = ({
    sortBy,
    sortDirection,
    page,
    rowsPerPage,
  }) => {
    searchQuery.set("sort_by", sortBy);
    searchQuery.set("sort_direction", sortDirection);
    searchQuery.set("page", page);
    searchQuery.set("perpage", rowsPerPage);
    // new URLSearchParams(query).forEach((value, key) => {
    //   searchQuery.set(key, value);
    // });
    history.push({
      search: searchQuery.toString(),
    });
  };

  const handleBackButton = () => {
    setShowDrilldownModal(false);
    setDrilldownAssetId(null);
  };

  const reducer = (state, action) => {
    updateUrlSearchParams({
      ...state,
      ...action,
    });

    return {
      ...state,
      ...action,
    };
  };

  const [queryInfo, dispatchQueryInfo] = useReducer(reducer, {
    query: defaultQuery,
    page: 1,
    rowsPerPage: DEFAULT_ROWS,
    sortBy: "criticality",
    sortDirection: "desc",
  });

  const impactHeaders = useMemo(
    () => addSolutionsToImpactHeaders(solutions),
    [solutions]
  );

  useEffect(() => {
    api
      .getApplications()
      .then((res) => setApplications(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api
      .getBusinessProcesses()
      .then((res) => setBusinessProcesses(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api
      .getActiveSolutions()
      .then((res) => {
        setSolutions(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (applications && solutions.length > 0) {
      updateFacetQuery(searchQuery.toString(), queryInfo);
    }
  }, [queryInfo, applications, searchQuery, solutions, forceReload]);

  const updateFacetQuery = useCallback(
    debounce(
      (queryFromUrl, queryInfo) => {
        setAssetDataLoading(true);
        api
          .assetsFacetedSearch(
            decodeURIComponent(queryFromUrl.replace(/\+/g, " ")) ||
              queryInfo.query,
            queryInfo.page,
            queryInfo.rowsPerPage,
            queryInfo.sortBy,
            queryInfo.sortDirection
          )
          .then((response) => {
            setData({
              headers: impactHeaders,
              rows: response.data,
              total: response.total_results,
              pages: response.total_pages,
              next_page: response.next_page,
            });
          })
          .catch((error) => console.log(error))
          .finally(() => {
            setAssetDataLoading(false);
          });
      },
      300,
      { maxWait: 700 }
    ),
    [applications, solutions]
  );

  useEffect(() => {
    if (window.location.pathname.includes(`${baseRoute}/assets`)) {
      setDocTitle("KeyCaliber - Asset Inventory");
    } else if (window.location.pathname.includes(`${baseRoute}/applications`)) {
      setDocTitle("KeyCaliber - Application Inventory");
    } else if (window.location.pathname.includes(`${baseRoute}/business-processes`)) {
      setDocTitle("KeyCaliber - Business Process Inventory");
    } else if (window.location.pathname.includes(`${baseRoute}/details`)) {
      setDocTitle("KeyCaliber - Asset Details");
    } else {
      setDocTitle("KeyCaliber");
    }
  }, [location]);

  const handleAssetDetail = (assetId) => {
    // setDrilldownAssetId(assetId);
    // setShowDrilldownModal(true);
    const link = `${window.location.origin}${baseRoute}/details/${assetId}`;
    window.open(link, "_blank");
  };

  const handleTabChange = (event, newValue) => {
    history.push(newValue);
  };

  const tabs = [
    {
      label: "Assets",
      name: "assets",
      path: `${baseRoute}/assets`,
    },
    {
      label: "Applications",
      name: "applications",
      path: `${baseRoute}/applications`,
    },
    {
      label: "Business Processes",
      name: "business-processes",
      path: `${baseRoute}/business-processes`,
    },
    // { label: "Subnets", name: "overview", path: `${baseRoute}/subnets` },
  ];

  document.title = docTitle;

  return (
    <Box className={classes.root} data-test="inventory-page">
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Inventory</Typography>
            <Typography color="textPrimary">{breadcrumb}</Typography>
          </Breadcrumbs> */}
          <RoundedTabs
            // The component prints an annoying error if we give it an
            // invalid value, but we frequently do that when visiting
            // routes that have no tab, so let's suppress that
            value={
              tabs.map((tab) => tab.path).includes(location.pathname)
                ? location.pathname
                : false
            }
            onChange={handleTabChange}
            aria-label="tabbed menu"
          >
            {/* <RoundedTab
              disabled
              label="Inventory"
            />
            <Divider orientation="vertical" flexItem /> */}
            {tabs.map(({ name, label, path }) => (
              <RoundedTab
                key={name}
                id={`tab-${name}`}
                label={label}
                value={path}
                data-test={`inventory-tab-${name}-link`}
              />
            ))}
          </RoundedTabs>
          <div>
            <Switch>
              <Route exact path="/app/inventory/assets">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper variant="outlined" ref={parentRef} elevation={0}>
                      <TabPanel aria-labelledby="tab-asset-inventory">
                        {showDrilldownModal ? (
                          <AssetDrilldownModal
                            handleBackButton={handleBackButton}
                            assetId={drilldownAssetId}
                          />
                        ) : (
                          <div
                            className={classes.paper}
                            data-test="inventory-tab-assets"
                          >
                            <InventoryFilters
                              rowsPerPage={DEFAULT_ROWS}
                              filterPanelOpen
                              dispatchQueryInfo={dispatchQueryInfo}
                              queryInfo={queryInfo}
                            />
                            <div style={{ width: "100%", overflow: "auto" }}>
                              {assetDataLoading && (
                                <div style={{ textAlign: "center" }}>
                                  <Typography>
                                    {" "}
                                    Asset Inventory Data is Loading...
                                  </Typography>
                                  <Typography>
                                    This may take a few minutes
                                  </Typography>
                                  <img
                                    src={spinner}
                                    style={{
                                      margin: "64px auto",
                                      display: "block",
                                    }}
                                    alt="Loading"
                                  />
                                </div>
                              )}
                              <AssetsTable
                                reloadTable={() => {
                                  setForceReload(!forceReload);
                                }}
                                tableActions={
                                  loadingDownload ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        padding: "0 .5em",
                                      }}
                                    >
                                      <CircularProgress size={20} />
                                    </div>
                                  ) : (
                                    <Tooltip
                                      placement="bottom"
                                      title={`Download ${numberWithCommas(
                                        data?.total
                                      )} to CSV`}
                                    >
                                      <IconButton
                                        disabled={!data?.total}
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                          marginLeft: "0",
                                          marginRight: ".5em",
                                        }}
                                        onClick={async () => {
                                          setLoadingDownload(true);
                                          try {
                                            await api.assetsCsvFacetedSearch(
                                              decodeURIComponent(
                                                searchQuery
                                                  ?.toString()
                                                  .replace(/\+/g, " ")
                                              ) || queryInfo.query
                                            );
                                          } finally {
                                            setLoadingDownload(false);
                                          }
                                        }}
                                      >
                                        <Icon>
                                          <img
                                            width="25px"
                                            src={downloadIcon}
                                            alt="Download CSV"
                                          />
                                        </Icon>
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }
                                applications={applications}
                                rowsPerPage={DEFAULT_ROWS}
                                loading={assetDataLoading}
                                updateSortingQueryParams={(property, order) => {
                                  dispatchQueryInfo({
                                    sortBy: property,
                                    sortDirection: order ? "asc" : "desc",
                                  });
                                }}
                                data={data}
                                setSelected={handleAssetDetail}
                                hideEditRow={false}
                                page={queryInfo.page - 1}
                                setPage={(page) => {
                                  dispatchQueryInfo({ page: page + 1 });
                                }}
                                n_assets={data?.total || 0}
                                setRowsPerPage={(rowsPerPage) => {
                                  dispatchQueryInfo({ rowsPerPage });
                                }}
                              />
                              {/* </div> */}
                            </div>
                          </div>
                        )}
                      </TabPanel>
                    </Paper>
                  </Grid>
                </Grid>
              </Route>
              <Redirect from={legacyDetailsRoute} to={detailsRoute} />
              <Redirect
                from="/app/inventory/edit/:assetId"
                to="/app/asset/edit/:assetId"
              />
              <Route exact path="/app/inventory/applications">
                <TabPanel
                  aria-labelledby="tab-application-inventory"
                  data-test="tab-application-inventory"
                >
                  <ApplicationsTable />
                </TabPanel>
              </Route>
              <Route exact path="/app/inventory/business-processes">
                <TabPanel
                  aria-labelledby="tab-business-process-inventory"
                  data-test="tab-business-process-inventory"
                >
                  <BusinessProcessesTable />
                </TabPanel>
              </Route>
              <Route>
                <PageNotFoundInner />
              </Route>
            </Switch>
          </div>
        </Box>
      </div>
    </Box>
  );
}

const CrownJewels = (props) => {
  return (
    <HoverPopoverProvider>
      <CrownJewelsInner redrawTopBar={props.redrawTopBar} />
    </HoverPopoverProvider>
  );
};

export default CrownJewels;
