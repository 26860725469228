import React, { useEffect, useState } from "react";
import { Typography, Card, Grid } from "@material-ui/core";
import { ResponsivePie } from "@nivo/pie";
import { useApi } from "../../API";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import { numberWithCommas } from "../../utils";
import { useHistory } from "react-router-dom";

function CyberAssetInventory() {
  const [pieData, setPieData] = useState([]);
  const [highImpactPieData, setHighImpactPieData] = useState([]);
  const [subnetCount, setSubnetCount] = useState({});
  const [zAssetCountAll, setZAssetCountAll] = useState(undefined);
  const [MCACountAll, setMCACountAll] = useState(undefined);
  const api = useApi();
  const history = useHistory();


  const reroute = (url) => {
    history.push(url);
  };

  useEffect(async () => {
    const res = await api.subnetCount();
    setSubnetCount(res);
  }, []);

  useEffect(async () => {
    const res = await api.allAssetTypeCount();
    const impactRes = await api.criticalityByAssetType();

    setPieData(res.filter(val => val.id !== 'internet'));
    setHighImpactPieData(
      impactRes.filter(val => val.id !== 'internet').map((val) => {
        return {
          value: val.assets,
          ...val,
        };
      })
    );
  }, []);

  useEffect(() => {
    api.settingsNavbar().then((res) => {
      setMCACountAll(res.num_critical);
      setZAssetCountAll(res.num_total);
    });
  }, []);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Your Cyber Asset Inventory" />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        textAlign="center"
        style={{ marginTop: "24px", marginBottom: "16px" }}
      >
        <Grid container item xs={4} justifyContent="center">
          <strong>All Assets</strong>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          {" "}
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <strong>High Impact Assets</strong>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "12px", marginBottom: "12px" }}
        >
          <Grid container item xs={4} justifyContent="center" onClick={() => reroute('/app/inventory/assets')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(zAssetCountAll)}{" "}
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="center">
            Total Number of Assets
          </Grid>
          <Grid container item xs={4} justifyContent="center" onClick={() => reroute('/app/inventory/assets?is_critical=true')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(MCACountAll)}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "12px", marginBottom: "12px" }}
        >
          <Grid container item xs={4} justifyContent="center" onClick={() => reroute('/app/resilience/subnets')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">{subnetCount.subnetsCount} </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="center">
            Total Number of Subnets (/16)
          </Grid>
          <Grid container item xs={4} justifyContent="center" onClick={() => reroute('/app/resilience/subnets')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {subnetCount.highImpactSubnetsCount}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <Grid style={{ height: 250 }} xs={4}>
            <ResponsivePie
              data={pieData}
              margin={{ top: 20, right: 10, bottom: 40, left: 10 }}
              innerRadius={0}
              colors={{ scheme: "pastel1" }}
              padAngle={0}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              onClick={(event) => reroute(`/app/inventory/assets?asset_type=${event.id}`)}
              arcLinkLabelsSkipAngle={9}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
            />
          </Grid>
          <Grid xs={4} direction="column"
            justifyContent="center"
            style={{ "textAlign": "center", "alignContent": "center" }}><div>Asset Types</div></Grid>
          <Grid style={{ height: 250 }} xs={4}>
            <ResponsivePie
              data={highImpactPieData}
              margin={{ top: 20, right: 10, bottom: 40, left: 20 }}
              innerRadius={0}
              padAngle={0}
              cornerRadius={3}
              colors={{ scheme: "pastel1" }}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              onClick={(event) => reroute(`/app/inventory/assets?is_critical=true&asset_type=${event.id}`)}
              arcLinkLabelsSkipAngle={9}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </ChartWrapper>
  );
}
export default CyberAssetInventory;
